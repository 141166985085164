/* Small devices (landscape phones, less than 576px) */
@media (max-width: 575.98px) {
  .breadcrumb-item {
    display: block;
  }

  #investmentAmount {
      width: 60vw;
      margin-left:2rem;
  
  }

  #investmentPeriod {
      width: 60vw;
      margin-left: 2rem;
  
  }
  #annualInterestRate {
      width: 60vw;
      margin-left: 2rem;
  }
  #about-img{
    width: 340px;
  }
  .lg-text{
    font-size: 30px;
  }
  #about-message{
    margin-top: 20px;
  }
  #about-para{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
  }
}


/* Medium devices (tablets, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .breadcrumb-item {
    display: inline-block;
  }

  #investmentAmount {
      width: 50vw;
      margin-left:6rem;
  
  }

  #investmentPeriod {
      width: 50vw;
      margin-left: 6rem;
  
  }
  #annualInterestRate {
      width: 50vw;
      margin-left: 6rem;
  }
  #about-img{
    width: 300px;
  }
}

/* Large devices (desktops, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .breadcrumb-item {
    display: inline-block;
  }
  #investmentAmount {
    width: 30vw;
    margin-left:6rem;

}

#investmentPeriod {
    width: 30vw;
    margin-left: 6rem;

}
#annualInterestRate {
    width: 30vw;
    margin-left: 6rem;
}
}

/* Extra large devices (large desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .breadcrumb-item {
    display: inline-block;
  }

  #investmentAmount {
    width: 30vw;
    margin-left:10rem;

}

#investmentPeriod {
    width: 30vw;
    margin-left: 10rem;

}
#annualInterestRate {
    width: 30vw;
    margin-left: 10rem;
}
}

/* Extra extra large devices (larger desktops, 1200px and up) */
@media (min-width: 1200px) {
  .breadcrumb-item {
    display: inline-block;
  }
  /* #investmentAmount {
    width: 30vw;
    margin-left:13rem;

}

#investmentPeriod {
    width: 30vw;
    margin-left: 13rem;

}
#annualInterestRate {
    width: 30vw;
    margin-left: 13rem;
} */
}
